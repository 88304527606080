const projectData = [
  {
    id: 1,
    projectName: "team website",
    projectDescription:
      "A perfect website template for any business that contained a lot and a lot of things such as a landing section to Introduce yourself, an articles section where you can put in it your featured articles, a gallery section to put the most important images of your business, features section to put in it the services you provide to your customers, testimonials section to put in it customers opinions, team member section to give them credit in it about what they have done and way more than that.",
    projectImage: "./images/mockup (11).webp",
    projectRepo: "https://github.com/Mohamed-Waled/webSite",
    projectLive: "https://mohamed-waled.github.io/webSite/",
    technologies: 3,
  },
  {
    id: 2,
    projectName: "original trombones landing page",
    projectDescription:
      "A product landing page you can use if your business is all about one product. It's a good landing page that tells the customer everything about you and your product.",
    projectImage: "./images/mockup (5).webp",
    projectRepo: "https://github.com/Mohamed-Waled/Product-Landing-Page",
    projectLive: "https://mohamed-waled.github.io/Product-Landing-Page/",
    technologies: 1,
  },
  {
    id: 3,
    projectName: "fylo landing page",
    projectDescription:
      " A Fylo landing page with two column layout you can use as a home page for your business, application, or even your portfolio as it's nice, tidy, and crystal clear and you can add to it whatever you want.",
    projectImage: "./images/mockup (2).webp",
    projectRepo:
      "https://github.com/Mohamed-Waled/Fylo-Landing-Page-With-Two-Column-Layout",
    projectLive:
      "https://mohamed-waled.github.io/Fylo-Landing-Page-With-Two-Column-Layout/",
    technologies: 1,
  },
  {
    id: 4,
    projectName: "huddle landing page",
    projectDescription:
      "A huddle landing page with alternating feature blocks you can use as a home page for your business, application, or even your portfolio as it's nice, tidy, and crystal clear and you can add to it whatever you want.",
    projectImage: "./images/mockup (3).webp",
    projectRepo:
      "https://github.com/Mohamed-Waled/Huddle-Landing-Page-With-Alternating-Feature-Blocks",
    projectLive:
      "https://mohamed-waled.github.io/Huddle-Landing-Page-With-Alternating-Feature-Blocks/",
    technologies: 1,
  },
  {
    id: 5,
    projectName: "clipboard landing page",
    projectDescription:
      "A clipboard landing page you can use as a home page for your business, application, or even your portfolio as it's nice, tidy, and crystal clear and you can add to it whatever you want.",
    projectImage: "./images/mockup.webp",
    projectRepo: "https://github.com/Mohamed-Waled/Clipboard-Landing-Page",
    projectLive: "https://mohamed-waled.github.io/Clipboard-Landing-Page/",
    technologies: 1,
  },
  {
    id: 6,
    projectName: "tribute page",
    projectDescription:
      "A tribute page that you can use on your website to tell people who visit your business history and how you developed it and yourself.",
    projectImage: "./images/mockup (10).webp",
    projectRepo: "https://github.com/Mohamed-Waled/Tribute-Page",
    projectLive: "https://mohamed-waled.github.io/Tribute-Page/",
    technologies: 1,
  },
  {
    id: 7,
    projectName: "survey form",
    projectDescription:
      "A survey form section that you can use to collect data from your users.",
    projectImage: "./images/mockup (7).webp",
    projectRepo: "https://github.com/Mohamed-Waled/Survey-Form",
    projectLive: "https://mohamed-waled.github.io/Survey-Form/",
    technologies: 1,
  },
  {
    id: 8,
    projectName: "personal portfolio webpage",
    projectDescription:
      "A personal portfolio webpage that contains what anybody needs in its portfolio like a little about yourself and your projects and you can add another page for every project to tell people about it and last but not least the footer section that has all your social media accounts.",
    projectImage: "./images/mockup (4).webp",
    projectRepo: "https://github.com/Mohamed-Waled/Personal-Portfolio-Webpage",
    projectLive: "https://mohamed-waled.github.io/Personal-Portfolio-Webpage/",
    technologies: 1,
  },
  {
    id: 9,
    projectName: "technical documentation page",
    projectDescription:
      "It's a technical documentation page but you can modify it to use it as terms of use and privacy policy for your website and add whatever data you want.",
    projectImage: "./images/mockup (8).webp",
    projectRepo:
      "https://github.com/Mohamed-Waled/Technical-Documentation-Page",
    projectLive:
      "https://mohamed-waled.github.io/Technical-Documentation-Page/",
    technologies: 1,
  },
  {
    id: 10,
    projectName: "random quote machine",
    projectDescription:
      "This is a random quote machine that uses API to get the quotes and make them appear in front of you.",
    projectImage: "./images/mockup (6).webp",
    projectRepo: "https://github.com/Mohamed-Waled/Random-Quote-Machine",
    projectLive: "https://celebrated-lily-314c2f.netlify.app/",
    technologies: 4,
  },
  {
    id: 11,
    projectName: "to-do app",
    projectDescription:
      "This is a To-Do App With Local Storage That Can Save Your Tasks in it Till You Delete Them includes an input field to write your task and a section to show you them and a button to delete the one you finish and when you click on any task it will be faded as it is done.",
    projectImage: "./images/mockup (9).webp",
    projectRepo: "https://github.com/Mohamed-Waled/To-Do-App",
    projectLive: "https://mohamed-waled.github.io/To-Do-App/",
    technologies: 2,
  },
  {
    id: 12,
    projectName: "distance between two points calculator",
    projectDescription:
      "This is an app to calculate the distance between two points In a 2-dimensional coordinate system in (X, Y) format.",
    projectImage: "./images/mockup (1).webp",
    projectRepo:
      "https://github.com/Mohamed-Waled/Distance-Calculator-Between-Two-Points-V-2.0",
    projectLive:
      "https://mohamed-waled.github.io/Distance-Calculator-Between-Two-Points-V-2.0/",
    technologies: 2,
  },
];

export default projectData;
