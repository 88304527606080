import React from "react";
import "./clock.scss";

function Clock() {
  let time = new Date(
    new Date().getTime() +
      new Date().getTimezoneOffset() * 60000 +
      3600000 * "+2.0"
  ).toLocaleTimeString();

  let date = new Date(
    new Date().getTime() +
      new Date().getTimezoneOffset() * 60000 +
      3600000 * "+2.0"
  ).toDateString();
  const [localTime, setLocalTime] = React.useState(time);
  const [localDate, setLocalDate] = React.useState(date);

  const updateTime = () => {
    let newlocaldate = new Date();
    let utc = newlocaldate.getTime() + newlocaldate.getTimezoneOffset() * 60000;
    let newDate = new Date(utc + 3600000 * "+3.0");
    let time = newDate.toLocaleTimeString();
    let date = newDate.toDateString();
    setLocalTime(time);
    setLocalDate(date);
  };

  setInterval(updateTime, 1000);

  return (
    <div className="clock">
      <h2 className="title">
        my local <span>time</span> and <span>date</span>
      </h2>
      <div className="timedate">{localDate}</div>
      <div className="timedate">{localTime}</div>
    </div>
  );
}

export default Clock;
