import React from "react";
import "./about.scss";
import { RiTimerFlashFill } from "react-icons/ri";
import { MdDevicesOther, MdAnimation, MdOutlineCode } from "react-icons/md";

function About() {
  return (
    <div className="aboutSection section" id="about">
      <div className="container">
        <div className="box">
          <div data-aos="slide-right" className="img">
            <img
              src="./images/imp.webp"
              alt="mohamed waled the website owner"
            />
          </div>
          <div className="about">
            <div data-aos="slide-left" className="mainTitle">
              <h1>about</h1>
            </div>
            <div data-aos="slide-left" className="jobTitle">
              <h2>front-end web developer</h2>
            </div>
            <div data-aos="slide-left" className="bio">
              <p>
                Hello, I'm Mohamed Waled a self-learner who started studying
                Front-End Development in December 2020. Since I began my
                journey, I have gained a lot of knowledge that I have progressed
                to learning Next.js.
              </p>
              <br />
              <p>
                With 2+ year of experience and 44+ Projects, I can build the
                Front-End of your website the way you want it on any device.
              </p>
              <br />
              <p>
                What comes after this? My ultimate objective is to become a
                competent and knowledgeable Software Engineer who comprehends
                their duties and performs them proficiently.
              </p>
            </div>
            <div data-aos="fade-up" className="services">
              <div className="icon">
                <RiTimerFlashFill />
                <p className="title">on time</p>
              </div>
              <div className="icon">
                <MdDevicesOther />
                <p className="title">responsive</p>
              </div>
              <div className="icon">
                <MdAnimation />
                <p className="title">animated</p>
              </div>
              <div className="icon">
                <MdOutlineCode />
                <p className="title">clean code</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
