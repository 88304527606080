import React from "react";
import "./landing.scss";

function Landing() {

  const date = new Date();
  const longMonth = date.toLocaleString('en-us', { month: 'long' });

  function scrollToSection() {
    const header = document.getElementById("header");
    header.scrollIntoView();
  }

  return (
    <div className="body">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      <div className="landingText">
        <p className="greet">
          hello, i'm <span className="name">mohamed waled</span>.
        </p>
        <p className="greet">i'm a front-end web developer.</p>
        <div className="button" onClick={scrollToSection}>
          curious ?
        </div>
      </div>
      <div className="glass" data-aos="slide-right">
        available in {longMonth}
        <img
          id="autumn"
          src="./images/Realistic-Autumn-Fall-Leaves-PNG.webp"
          alt="Autumn-Leave"
        />
      </div>
    </div>
  );
}

export default Landing;
