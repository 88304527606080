import React from "react";
import "./footer.scss";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import { SiFrontendmentor, SiFreecodecamp } from "react-icons/si";
import Clock from "../Clock/Clock";

function footer() {
  return (
    <div id="footer" className="footer">
      <div className="container">
        <div className="box">
          <div className="contactInfo" data-aos="slide-right">
            <h1 className="logo">
              <span>mohamed</span> waled<span>'s</span>
            </h1>
            <div className="contactIcons">
              <div className="icon linkedIn">
                <a
                  href="https://www.linkedin.com/in/mohamed-waled-82a51a1bb/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin />
                </a>
              </div>
              <div className="icon gitHub">
                <a
                  href="https://github.com/Mohamed-Waled"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub />
                </a>
              </div>
              <div className="icon frontendMentor">
                <a
                  href="https://www.frontendmentor.io/profile/Mohamed-Waled"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SiFrontendmentor />
                </a>
              </div>
              <div className="icon freeCodeCamp">
                <a
                  href="https://www.freecodecamp.org/mohamedwaledfarag"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SiFreecodecamp />
                </a>
              </div>
            </div>
          </div>
          <div className="workInfo" data-aos="fade-up">
            <h2 className="title">
              Business <span>hours</span>
            </h2>
            <p className="workingtime">
              Sunday - Thursday ( 08:00 AM - 04:00 PM )
            </p>
            <p className="workingtime">Friday and Saturday are my days off</p>
          </div>
          <div className="timeDateEG" data-aos="slide-left">
            <Clock />
          </div>
        </div>
      </div>
    </div>
  );
}

export default footer;
