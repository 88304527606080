import React from "react";
import "./portfolio.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, Autoplay, EffectCoverflow } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import Modal from "react-modal";
import { FaReact, FaSass, FaGithub } from "react-icons/fa";
import { AiFillHtml5, AiFillCloseSquare } from "react-icons/ai";
import { SiJavascript } from "react-icons/si";
import { DiCss3 } from "react-icons/di";
import projectsData from "./projectsdata";

Modal.setAppElement("#root");
function Portfolio() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState(projectsData[0]);

  return (
    <div className="portfolio section" id="portfolio">
      <div className="container" data-aos="fade-up">
        <div className="mainTitle">
          <h1>portfolio</h1>
        </div>
        <Swiper
          modules={[Navigation, A11y, Autoplay, EffectCoverflow]}
          navigation={true}
          loop={true}
          effect={"coverflow"}
          centeredSlides={true}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              spaceBetween: 50,
              slidesPerView: 3,
            },
          }}
          autoplay={{ delay: 4000 }}
        >
          {projectsData.map((project) => {
            return (
              <SwiperSlide key={project.id}>
                <img src={project.projectImage} alt={project.projectName} />
                <div className="topTie">
                  <h2>{project.projectName}</h2>
                </div>
                <div className="bottomTie">
                  <button
                    className="projectsBotton"
                    onClick={() => {
                      setIsModalOpen(true);
                      setModalData(project);
                    }}
                  >
                    learn more
                  </button>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {modalData ? (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          className="Modal"
          overlayClassName="modalOverLay"
        >
          <div className="modal">
            <div className="closeIcon">
              <AiFillCloseSquare onClick={() => setIsModalOpen(false)} />
            </div>
            <div className="topHeader">
              <h2 className="modalTitle">{modalData.projectName}</h2>
              <div className="icon">
                <a
                  href={modalData.projectRepo}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub />
                </a>
              </div>
            </div>
            <iframe
              src={modalData.projectLive}
              title={modalData.projectName}
              frameBorder="0"
              className="liveSite"
            />
            <h3 className="descriptionTitle">what about this website ?</h3>
            <p className="description">{modalData.projectDescription}</p>
            <h3 className="descriptionTitle">What technologies i've used ?</h3>
            <div className="technologies">
              {modalData.technologies === 1 ? (
                <div className="icons">
                  <div className="icon">
                    <AiFillHtml5 />
                  </div>
                  <div className="icon">
                    <DiCss3 />
                  </div>
                </div>
              ) : modalData.technologies === 2 ? (
                <div className="icons">
                  <div className="icon">
                    <AiFillHtml5 />
                  </div>
                  <div className="icon">
                    <DiCss3 />
                  </div>
                  <div className="icon">
                    <SiJavascript />
                  </div>
                </div>
              ) : modalData.technologies === 3 ? (
                <div className="icons">
                  <div className="icon">
                    <AiFillHtml5 />
                  </div>
                  <div className="icon">
                    <FaSass />
                  </div>
                  <div className="icon">
                    <SiJavascript />
                  </div>
                </div>
              ) : modalData.technologies === 4 ? (
                <div className="icons">
                  <div className="icon">
                    <AiFillHtml5 />
                  </div>
                  <div className="icon">
                    <FaSass />
                  </div>
                  <div className="icon">
                    <FaReact />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}

export default Portfolio;
