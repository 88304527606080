import React from "react";
import Home from "./components/home/Home";
import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  React.useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div>
      <Home />
    </div>
  );
}

export default App;
