import React from "react";
import About from "../aboutSection/About";
import Break from "../breakSection/Break";
import ContactForm from "../contactForm/ContactForm";
import Header from "../header/Header";
import Landing from "../landing/Landing";
import Portfolio from "../portfolioSection/Portfolio";
import Footer from "../footer/Footer";
import { BsFillArrowUpSquareFill } from "react-icons/bs";

import "./home.scss";

function Home() {
  function scrollToSection() {
    const about = document.getElementById("about");
    about.scrollIntoView();
  }
  const ref = React.useRef();

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 1400) {
        ref.current.classList.add("showUp");
      } else {
        ref.current.classList.remove("showUp");
      }
    });
  });

  return (
    <div className="home">
      <Landing />
      <div className="overlay"></div>
      <Header />
      <About />
      <Break>
        <h1 className="breakTitle">work</h1>
        <p className="breakDescription">
          More about my work, portfolio, testimonials and services.
        </p>
      </Break>
      <Portfolio />
      <Break>
        <h1 className="breakTitle">say hello</h1>
        <p className="breakDescription">
          Want to reach out? This is the right place.
        </p>
        <p className="breakDescription">
          Send me an e-mail, I'm waiting for you.
        </p>
      </Break>
      <ContactForm />
      <Footer />
      <div className="backToToputton" onClick={scrollToSection} ref={ref}>
        <BsFillArrowUpSquareFill />
      </div>
    </div>
  );
}

export default Home;
